import type { CallHandle } from 'janus-manager';
import type { Contact, Pbx } from '~/types';

interface JanusContext {
    error: (error: Error) => void;
    destroy: () => void;
}

export const useJanusStore = defineStore('janus', () => {
    const handler = ref<CallHandle | null>(null);
    const context = ref<JanusContext | null>(null);
    const showCallPopup = ref(false);
    const muted = ref(false);
    const callStatus = ref<'initiating' | 'calling' | 'accepted' | 'ended' | 'incoming' | ''>('');
    const credentials = ref<Pbx | null>(null);
    const running = ref(false);
    const connected = ref(false);
    const calling = ref(false);
    const jsep = ref<JSEP | null>(null);
    const phoneNumber = ref<string | null>(null);
    const hangingUp = ref(false);
    const remoteMedia = ref<HTMLVideoElement | null>(null);
    const contact = ref<Partial<Contact> | null>(null);

    function reset() {
        handler.value = null;
        context.value = null;
        showCallPopup.value = false;
        muted.value = false;
        callStatus.value = '';
        remoteMedia.value = null;
        credentials.value = null;
        running.value = false;
        connected.value = false;
        calling.value = false;
        jsep.value = null;
        contact.value = null;
        phoneNumber.value = null;
    }

    return {
        handler,
        context,
        showCallPopup,
        muted,
        callStatus,
        remoteMedia,
        credentials,
        running,
        connected,
        calling,
        jsep,
        contact,
        phoneNumber,
        hangingUp,
        reset,
    };
});
