import { z } from 'zod';

export const leadSchema = z.object({
    company_id: z.coerce.number().int(),
    created_at: z.coerce.date().optional().nullable(),
    id: z.coerce.number().int(),
    lead_status_id: z.coerce.number().int().optional().nullable(),
    rating_id: z.coerce.number().int(),
    updated_at: z.coerce.date().optional().nullable(),
    lead_source_id: z.string().optional().nullable(),
    user_id: z.string().trim(),
    organization_id: z.coerce.number().int(),
});

export const addLeadSchema = z.object({
    company_name: z.string().trim().min(1, { message: 'Company name must be at least 1 character long' }),
    lead_source_id: z.number().min(1, { message: 'Source must be selected' }),
});

export const addLeadFromInboxSchema = addLeadSchema
    .pick({
        company_name: true,
        lead_source_id: true,
    })
    .extend({
        mobile_phone: z.string().trim().min(1, { message: 'Phone number is required' }),
        first_name: z.string().trim().min(1, { message: 'First name is required' }),
    });

export const addLeadToExistingLeadSchema = z.object({
    company_id: z.number().min(1, { message: 'Company must be selected' }),
    mobile_phone: z.string().trim().min(1, { message: 'Phone number is required' }),
    first_name: z.string().trim().min(1, { message: 'First name is required' }),
});

export const updateLeadUserIdSchema = leadSchema.pick({
    user_id: true,
});
