export default defineNuxtRouteMiddleware(async (to) => {
    const EXCLUDED_PATHS = new Set([
        '/auth/verify-email',
        '/auth/forgot-password',
        '/auth/forgot-password/reset',
        '/loading',
        '/server-error',
    ]);

    if (EXCLUDED_PATHS.has(to.path)) return;

    const nuxtApp = useNuxtApp();

    const session = import.meta.server ? useSupabaseSession() : storeToRefs(userSessionStore()).session;
    const currentSession = session?.value;

    if (!currentSession) {
        if (to.path !== '/auth/signin' && to.path !== '/auth/signup') {
            return navigateTo('/auth/signin');
        }
        return;
    }

    const { data: user, error } = await useFetch(`/api/users/${currentSession.user.id}`, {
        key: `user-${currentSession.user.id}`,
        getCachedData: (key) => nuxtApp.payload.data[key],
        default: () => null,
    });

    if (error.value?.statusCode?.toString().startsWith('5')) {
        return navigateTo('/server-error');
    }

    if (to.path !== '/auth/onboarding' && !currentSession.user.user_metadata.organization_id) {
        return navigateTo('/auth/onboarding');
    }

    if (to.path.startsWith('/auth') && currentSession.user.user_metadata.organization_id && user?.value?.status === 'active') {
        return navigateTo('/dashboard');
    }

    if (
        to.path !== '/auth/calendar' &&
        user?.value?.status === 'waiting confirmation' &&
        currentSession.user.user_metadata.hasBooked === false
    ) {
        return navigateTo('/auth/calendar');
    }

    if (
        to.path !== '/auth/booked' &&
        user?.value?.status === 'waiting confirmation' &&
        currentSession.user.user_metadata.hasBooked === true
    ) {
        return navigateTo('/auth/booked');
    }
});
