import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin(() => {
    if (import.meta.client) {
        const script = document.createElement('script');
        script.src = '/whatsappChatbox.min.js';
        script.type = 'text/javascript';
        script.onload = () => {
            const WAChatBox = (window as any).WAChatBox;
            if (WAChatBox) {
                new WAChatBox({
                    link: 'https://api.whatsapp.com/send?phone=6285155330889&text=Halo%2C%20saya%20ingin%20tahu%20lebih%20lanjut%20tentang%20solusi%20Lead%20Generation%20dari%20IDB2B',
                    user: {
                        name: 'IDB2B',
                        avatar: '/favicon.ico',
                        status: 'Typically replies within an hour',
                    },
                    text: `Hey There 👋<br><br>I'm here to help, so let me know what's up and I'll be happy to find a solution 🤓`,
                    button_text: 'Need Help?',
                    button_color: 'red',
                });
            } else {
                console.error('WAChatBox is not defined');
            }
        };
        document.head.appendChild(script);
    }
});
